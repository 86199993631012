// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-article-page-js": () => import("./../src/templates/articlePage.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-certifications-js": () => import("./../src/templates/certifications.js" /* webpackChunkName: "component---src-templates-certifications-js" */),
  "component---src-templates-company-js": () => import("./../src/templates/company.js" /* webpackChunkName: "component---src-templates-company-js" */),
  "component---src-templates-consulting-js": () => import("./../src/templates/consulting.js" /* webpackChunkName: "component---src-templates-consulting-js" */),
  "component---src-templates-contact-js": () => import("./../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-news-list-page-js": () => import("./../src/templates/newsListPage.js" /* webpackChunkName: "component---src-templates-news-list-page-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-policy-page-js": () => import("./../src/templates/policyPage.js" /* webpackChunkName: "component---src-templates-policy-page-js" */),
  "component---src-templates-privacy-js": () => import("./../src/templates/privacy.js" /* webpackChunkName: "component---src-templates-privacy-js" */),
  "component---src-templates-reference-js": () => import("./../src/templates/reference.js" /* webpackChunkName: "component---src-templates-reference-js" */),
  "component---src-templates-references-js": () => import("./../src/templates/references.js" /* webpackChunkName: "component---src-templates-references-js" */),
  "component---src-templates-sectors-page-js": () => import("./../src/templates/sectorsPage.js" /* webpackChunkName: "component---src-templates-sectors-page-js" */),
  "component---src-templates-solution-js": () => import("./../src/templates/solution.js" /* webpackChunkName: "component---src-templates-solution-js" */),
  "component---src-templates-solutions-js": () => import("./../src/templates/solutions.js" /* webpackChunkName: "component---src-templates-solutions-js" */),
  "component---src-templates-superbonus-js": () => import("./../src/templates/superbonus.js" /* webpackChunkName: "component---src-templates-superbonus-js" */)
}

